import Rails from '@rails/ujs';
import { Controller } from 'stimulus'; 
import Chartkick from "chartkick"
import Chart from "chart.js"

export default class extends Controller {
  connect() { 
    Chartkick.use(Chart)
  }
}
