import Rails from '@rails/ujs';
import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ['form']

  connect() { }

  submitForm(e) {
    Rails.fire(this.element, 'submit');
  }

}
